import { StripDiv, LabelInfo } from './style';

export default function MovePanelStrip(){
	return (
		<StripDiv>
			<LabelInfo>
				<svg width="19.35" height="22" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.97724 22.2524H1V1H16.9393V7.64138" 
						stroke='#64686A' strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M7.64258 16.939H20.9253M7.64258 16.939L9.68608 14.8955M7.64258 16.939L9.68608 18.9825M20.9253 
					16.939L18.8818 14.8955M20.9253 16.939L18.8818 18.9825M14.284 10.2976V23.5804M14.284 
					10.2976L12.2405 12.3411M14.284 10.2976L16.3275 12.3411M14.284 23.5804L12.2405 21.5369M14.284 23.5804L16.3275 21.5369" 
					stroke="#64686A" strokeWidth="1.36032" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				<label>Move Panel</label>
			</LabelInfo>
		</StripDiv>
		
	);
}