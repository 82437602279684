import { memo, useMemo } from 'react';
import { Line } from 'react-konva';
import Konva from 'konva';
import { FACET_HIGHLIGHT_OFF_COLOR, KONVA_FACET_GROUP } from '../../../constants';
import { KonvaGroupNames } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

interface Props extends Konva.LineConfig {
	roofSegment: RasterRoofSegment;
}

const Facet = memo((props: Props) => {
	const { roofSegment } = props;
	const scale = useKonvaImageScale();

	const transformedPoints = useMemo(() => 
		roofSegment?.simplifiedHullCoords?.flat()?.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y), 
	[roofSegment?.simplifiedHullCoords, scale.x, scale.y]);

	return (
		<Line
			name={`${KonvaGroupNames.existingFacetsGroup}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}facet`}
			id={roofSegment.id}
			closed={true}
			strokeWidth={1}
			points={transformedPoints}
			draggable={false}
			fill={FACET_HIGHLIGHT_OFF_COLOR}
		/>);
});

Facet.displayName = 'Facet';
export default Facet;