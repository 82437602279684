import Button from 'components/Basic/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { useRefs } from 'contexts/RefContext';
import { DESIGN_MODES } from '../../../../constants';
import { toggleManualToolModalState } from 'store/slices/ManualToolModal';
import { checkIfLayoutNeedsToBeReset } from '../util';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { toggleRecalculateLayoutModalSliceState } from 'store/slices/RecalculateLayoutSlice';

import { resetDeletedObstructionIDs, setDeletedRoofIndexes, setRoofIndexes, 
	setUserModificationState, updateTransition } from 'store/slices/ToolSlice';
import { setPanelMove, setAddPanel, setDeleteIndividualPanelsMode } from 'store/slices/PanelSlice';

import useCreateDeleteFacet from 'hooks/api/facet/useCreateDeleteFacet';
import useTranslatePanelPositionsMutation from 'hooks/api/facet/useTranslateFacet';
import useMovePanelsQuery from 'hooks/api/panel/movePanel';
import useRemovePanelsQuery from 'hooks/api/panel/removePanel';
import useAddPanelsQuery from 'hooks/api/panel/addPanel';
import useDeleteObstructionQuery from 'hooks/api/deleteObstruction';

const MutationKeys= [
	'create-new-facets', 'add-panels', 'remove-panels', 'move-panels', 'translate-panels', 'delete-obstructions'
];

export default function CreateBtn() {
	const { newlyCreatedFacets, deletedRoofIndexes, roofIndexes, deletedObstructionIDs, groundMountEnabled } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { newlyAddedPanels, deletedPanels, currentMovablePanels, activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { uuid, panel, mode, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const defaultMode = mode === DESIGN_MODES.DEFAULT;
	const { konvaRef } = useRefs();
	const dispatch = useDispatch<AppDispatch>();
	const showLoader = useQueriesRunningInBackGround( MutationKeys, 'mutating');

	const hasTransformedFacet = !!roofIndexes.length;
	const hasCreatedFacet = !!Object.keys(newlyCreatedFacets).length;
	const hasDeletedFacet = !!deletedRoofIndexes.length;
	const hasDeletedObstruction = !!deletedObstructionIDs.length;

	const hasPanelsAdded = newlyAddedPanels.length > 0;
	const hasPanelsDeleted = deletedPanels.length > 0;
	const hasPanelsMoved = currentMovablePanels.length > 0;

	const disableOnPartialFacet = !activePanelMode && !hasTransformedFacet && !(hasCreatedFacet || hasDeletedFacet || hasDeletedObstruction);
	const disableOnPartialPanel = activePanelMode && !(hasPanelsAdded || hasPanelsDeleted|| hasPanelsMoved);
	const disableDoneButton = !!showLoader || disableOnPartialFacet || disableOnPartialPanel;

	const createNewFacetsMutation = useCreateDeleteFacet();
	const translatePanelPositionsMutation = useTranslatePanelPositionsMutation();

	const addNewPanelsQuery = useAddPanelsQuery();
	const removePanelsQuery = useRemovePanelsQuery();
	const movablePanelsQuery = useMovePanelsQuery();

	const deleteObstructionsQuery = useDeleteObstructionQuery();

	function beforeHandleClick(){
		const showResetLayoutMessage = konvaRef?.current && checkIfLayoutNeedsToBeReset(konvaRef.current, roofIndexes, allRoofSegs);
		
		if (showResetLayoutMessage) {
			dispatch(toggleRecalculateLayoutModalSliceState({
				open: true,
				warningMessage: 'Roof pitch changes will recalculate the layout. Proceed?',
			}));
		} else {
			handleClick();
		}
	}

	function resetAllMOdifiedState(){
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(setAddPanel({ shouldEnable: false }));
		dispatch(setDeleteIndividualPanelsMode({ enabled: false }));

		dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [] }));
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		
		dispatch(resetDeletedObstructionIDs());
		dispatch(setUserModificationState({ hasMadeChanges: true }));
		dispatch(updateTransition({ currentTransitionState: false }));
	}

	async function handleClick(){
		const isNewlyAddedFacetsGroundMountInDefaultMode = defaultMode && groundMountEnabled;
		const hasFacetCreatedInNonDefaultModes = !defaultMode && hasCreatedFacet;
		const showManualModal = isNewlyAddedFacetsGroundMountInDefaultMode || hasFacetCreatedInNonDefaultModes;
		if (showManualModal) {
			dispatch(toggleManualToolModalState({ value: true }));
			return;
		}
		
		const mutations = [];
		if(hasCreatedFacet || hasDeletedFacet) mutations.push(createNewFacetsMutation.mutateAsync());

		if (hasPanelsAdded) mutations.push(addNewPanelsQuery.mutateAsync());
		if (hasPanelsDeleted) mutations.push(removePanelsQuery.mutateAsync());
		if (hasPanelsMoved) mutations.push(movablePanelsQuery.mutateAsync());

		if (hasDeletedObstruction) mutations.push(deleteObstructionsQuery.mutateAsync());
		if(hasTransformedFacet) mutations.push(translatePanelPositionsMutation.mutateAsync());

		await Promise.allSettled(mutations);
		await dispatch(changePanel({ uuid, panelKey: panel.key }));
		resetAllMOdifiedState();
	}

	return (
		<Button
			onClick={beforeHandleClick}
			style={{ background: '#003CFF', color:  '#fff' }}
			showLoader={showLoader}
			disabled={disableDoneButton}
			className='loaderbefore close-icon btn-done'
		>
			<svg width="22" height="22.5" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.94727 10.2716L13.2626 13.5088C13.53 13.7093 13.8632 13.8014 14.1956 13.7665C14.528 13.7316 14.8348 
				13.5724 15.0547 13.3207L23.5172 3.64893" stroke="#fff" strokeWidth="1.69284" strokeLinecap="round"/>
				<path d="M24.8418 12.9207C24.8418 15.4115 24.0617 17.8398 22.6108 19.8645C21.16 21.8892 19.1114 23.4085 16.7527 
				24.2092C14.394 25.0098 11.8438 25.0515 9.46027 24.3284C7.07669 23.6053 4.97949 22.1538 3.46322 20.1776C1.94695 
				18.2015 1.08777 15.8 1.00637 13.3105C0.924968 10.821 1.62543 8.3685 3.00936 6.29751C4.3933 4.22652 6.3912 2.64106 
				8.72244 1.7638C11.0537 0.886549 13.6012 0.761569 16.0071 1.40642" stroke="#fff" strokeWidth="1.69284" strokeLinecap="round"/>
			</svg>
			<label>Done</label>
		</Button>

	);

}