import { StripDiv, LabelInfo } from './style';

export default function DeletePanelStrip(){
	return (
		<StripDiv>
			<LabelInfo>
				<svg width="19.5" height="21.5" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.03074 22.6371H0.945312V1H17.1731V7.76159" 
						stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M10.5 12.8V23H19.5V12.8H10.5Z" stroke="#64686A" strokeWidth="1.69" strokeLinejoin="round"/>
					<path d="M13.8 15.8V19.7M16.2 15.8V19.7M9 12.8H21" 
						stroke="#64686A" strokeWidth="1.69" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M11.6016 12.8L12.5883 10H17.4347L18.4016 12.8H11.6016Z" 
						stroke="#64686A" strokeWidth="1.69" strokeLinejoin="round"/>
				</svg>
				<label>Delete Panel</label>
			</LabelInfo>
		</StripDiv>
		
	);
}