import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Group } from 'react-konva';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { SEMI_TRANPARENT_BLACK_PANEL, FULL_TRANPARENT_BLACK_PANEL, FACET_HIGHLIGHT_COLOR, 
	FACET_HIGHLIGHT_OFF_COLOR } from '../../../constants';
import { getExistingFacetsGroupID, getRoofSegmentIDFromGroupID, KonvaGroupNames, resetAllTransformations } from '../utils';

import { setRoofIndexes, setSelectedRoofIndex, updateTransition } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';

import Facet from '../Facet';
import PanelsGroup from './PanelsGroup';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import TransformerComponent from './PanelsGroup/Transformer';

function FacetGroup() {
	const { roofSegs, allRoofSegs, } = useSelector((state: RootState) => state.roofData.data);
	const editRoofState = useSelector((state: RootState) => state.toolNewPostions.data);
	const { roofIndexes, editModeEnabled, selectedRoofIndex, deletedRoofIndexes, moveStage, currentActiveFacetEditMode } = editRoofState;
	const { showIrradiance, showPanels } = useSelector((state: RootState) => state.Irradiance.data);
	const isIndividualPanelModeActive = useSelector((state: RootState) => state.panelSlice.data.activePanelMode);
	const listenToEventsOnGroup = editModeEnabled && !isIndividualPanelModeActive;
	
	const trRef = useRef<Konva.Transformer>(null);
	const currentSelectedFacetGroup = useRef<Konva.Group>();
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();

	const hideFacetGrpInIrradianceMode = (showIrradiance && !showPanels);
	const selectedRoofSegment = useMemo(() => (allRoofSegs.find(r => r.id === selectedRoofIndex)), [selectedRoofIndex, allRoofSegs]);

	function addRoofIndex() {
		const roofIndex = getRoofSegmentIDFromGroupID(trRef.current?.getNode()?.attrs.id);
		if(!roofIndex) return;
		if (!isIndividualPanelModeActive)
			dispatch(updateTransition({ currentTransitionState: true }));
		if (!roofIndexes.includes(roofIndex))
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, roofIndex] }));
	}

	const makeFacetSelectable = useCallback((facetgroup: Konva.Group) => {
		const roofIndex = getRoofSegmentIDFromGroupID(facetgroup.id());
		if (!roofIndex || roofIndex === selectedRoofIndex) return;
		dispatch(setSelectedRoofIndex({ selectedRoofIndex: roofIndex }));
	}, [selectedRoofIndex, dispatch]);

	const makeFacetTranslative = useCallback((facetgroup: Konva.Group) => {
		trRef.current?.detach();
		
		const panelGroup = facetgroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group;
		if (!panelGroup.hasChildren()) return;
		
		//rotate grp and counter rotate child to point transformer handle towards segments'azimuth
		if (!panelGroup.attrs.rotation) {
			const roofSegmentID = getRoofSegmentIDFromGroupID(facetgroup.id());
			const rs = allRoofSegs.find(r => r.id === roofSegmentID);
			if (!rs) return;
			panelGroup?.rotation(rs.azimuthDegrees);
			panelGroup?.getChildren().forEach(e => e.rotation(-rs.azimuthDegrees));
		}

		panelGroup.draggable(true);
		trRef.current?.setNodes([panelGroup]);
	},[allRoofSegs]);

	function changePanelAlphanes(facetgroup: Konva.Group, action: 'ENTER' | 'LEAVE'){
		const panelGroup = facetgroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group | undefined;
		if (!panelGroup?.children?.length) return;
		const lines = panelGroup?.children as Konva.Line[];
		for (let i = 0; i < lines.length; i++) {
			lines[i].attrs['fill'] = action === 'ENTER' ? FULL_TRANPARENT_BLACK_PANEL : SEMI_TRANPARENT_BLACK_PANEL;
		}
	}

	function toggleFacetHighlight(facetgroup: Konva.Group, action: 'ENTER' | 'LEAVE') {
		if (!facetgroup?.children?.length) return;
		const lines = facetgroup?.children as Konva.Line[];
		if (lines[0]) {
			lines[0].attrs['fill'] = action === 'ENTER' ? FACET_HIGHLIGHT_COLOR : FACET_HIGHLIGHT_OFF_COLOR;
		}
	}

	const roofSegments = useMemo(() => editModeEnabled ? allRoofSegs : Object.values(roofSegs),
		[editModeEnabled, allRoofSegs, roofSegs]);

	useEffect(() => {
		// reset transformations when roofSegments are updated in edit mode
		if (konvaRef?.current && editModeEnabled) {
			dispatch(setSelectedRoofIndex({ selectedRoofIndex: null }));
			resetAllTransformations(konvaRef?.current);
			konvaRef.current.container().style.cursor = 'default';
		}
	
	}, [allRoofSegs, konvaRef, currentActiveFacetEditMode, editModeEnabled, isIndividualPanelModeActive, dispatch]);

	const handleGroupClick = useCallback((event: KonvaEventObject<PointerEvent>) => {
		if (!listenToEventsOnGroup) return;
		
		const previousSelectedGroup = currentSelectedFacetGroup.current;
		const targetFacetGroup = event.currentTarget as Konva.Group;
		console.log('facet', targetFacetGroup.id());

		if (!previousSelectedGroup) {
			changePanelAlphanes(targetFacetGroup, 'ENTER');
			toggleFacetHighlight(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			currentSelectedFacetGroup.current = targetFacetGroup;
			return;
		}

		if (previousSelectedGroup.id() !== targetFacetGroup.id()) {
			changePanelAlphanes(previousSelectedGroup, 'LEAVE');
			toggleFacetHighlight(previousSelectedGroup, 'LEAVE');

			changePanelAlphanes(targetFacetGroup, 'ENTER');
			toggleFacetHighlight(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			currentSelectedFacetGroup.current = targetFacetGroup;
			return;
		}

		if (previousSelectedGroup.id() === targetFacetGroup.id()) {
			changePanelAlphanes(targetFacetGroup, 'ENTER');
			toggleFacetHighlight(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			return;
		}
	}, [listenToEventsOnGroup, makeFacetSelectable, makeFacetTranslative]);

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!listenToEventsOnGroup) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			openEditFacetPopup({ x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY });
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}

	function handleContextMenuEvt(event: KonvaEventObject<PointerEvent>) {
		if (!listenToEventsOnGroup) return;
		event.evt.preventDefault();
		handleGroupClick(event);
		openEditFacetPopup({ x: event.evt.clientX, y: event.evt.clientY });
	}

	function openEditFacetPopup(position: Vector2d) {
		dispatch(setDropdownState({ open: true, position }));
	}

	function handleDragStart() {
		if (!listenToEventsOnGroup) return;
		if (konvaRef?.current) {
			clearTimeout(longTouchTimerRef.current);
			konvaRef.current.container().style.cursor = 'move';
		}
	}

	function handleMouseOver(event: KonvaEventObject<PointerEvent>){
		if(!listenToEventsOnGroup) return;
		const targetFacetGroup = event.currentTarget as Konva.Group;
		const checkFacetHasPanels = !!targetFacetGroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`)?.hasChildren();
		if (konvaRef?.current)
			konvaRef.current.container().style.cursor = checkFacetHasPanels? 'move' : 'default';
	}

	return (
		<>
			{
				roofSegments.map((rs) => {
					if (deletedRoofIndexes.includes(rs.id)) return null;
					const groupId = `${getExistingFacetsGroupID(rs.id)}`;
					return (
						<Group
							key={rs.id}
							id={groupId}
							listening={!moveStage && !showIrradiance && !currentActiveFacetEditMode}
							name={KonvaGroupNames.existingFacetsGroup}
							visible={!hideFacetGrpInIrradianceMode}
							onContextMenu={handleContextMenuEvt}
							onClick={handleGroupClick}
							onTap={handleGroupClick}
							onPointerOver={handleMouseOver}
							onTouchStart={handleTouchStart}
							onTouchEnd={handleTouchEndOrCancel}
							onPointerLeave={() => {
								if (!listenToEventsOnGroup) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							onDragStart={handleDragStart}
							onDragEnd={() => {
								if (!listenToEventsOnGroup) return;
								addRoofIndex();
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
						>
							<Facet roofSegment={rs} />
							<PanelsGroup roofSegment={rs} />
						</Group>
					);
				})
			}

			<TransformerComponent 
				ref={trRef}
				visible={!hideFacetGrpInIrradianceMode && !showIrradiance}
				selectedRoofSegment={selectedRoofSegment} 
				onTransformEnd={addRoofIndex} />
		</>
	);
}
export default memo(FacetGroup);