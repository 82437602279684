import styled from 'styled-components';

export const StripDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: .5rem;
	padding-left:.35rem;
	label{
		font-size: 0.813rem;
		color: #4A525A;
		transition-delay: 1s;
		font-family: Inter;
		position:relative;
	}
`;
export const LabelInfo = styled.div`
		font-size: 0.813rem;
		color: #4A525A;
		transition-delay: 1s;
		font-family: Inter;
		font-weight:600;
		position:relative;
		padding-right: .15rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: .5rem;
		min-height:27px;
}
`;