import styled, { Interpolation } from 'styled-components';
type props = {
	styles?: Interpolation<React.CSSProperties>
	fullScreenEnabled?:boolean
}

export const StageWrap = styled.div` 
	height:100%;
	position:relative;
	overflow: hidden;
	// inorder to set the height here try adjusting the scale first
	// in index.tsx tool
	// height: 300px;
	// width: 800px;

	.production-indicator {
		width: 40%;
    position: absolute;
    z-index: 1;
    left: 1rem;
    top: 1rem;
	}
	
	@media (max-width: 500px){
		.production-indicator {
			width: 89%;
		}
	}
	@media only screen and (min-width:1200px){
		// height:100%;
	}
	.konvajs-content{
		// max-height: 300px;
		@media only screen and (min-width:1200px){
			// width:100%!important;
			// height:100%!important;
			// max-height:100%;
		}
		canvas{
			// max-height:100%;
			object-fit:cover;	
			object-position:center;
			// @media only screen and (min-width:1200px){
			// 	width:100%!important;
			// 	height:100%!important;
			// }
		}
	}
	> div{
		height:100%;
		position: relative;
		// @media (max-width: 500px){
		// 	right: 28%;
		// }
		// @media (max-width: 400px){
		// 	right: 36%;
		// }
		// @media (max-width: 375px){
		// 	right: 38%;
		// }
	}
@media (max-width: 935px){
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 640px){
	display: block;
	justify-content: center;
	align-items: center;
}
	
`;

export const FlexDiv= styled.div`
background: url(bg3dsq.png)  repeat;
background-position: center;
position: relative;
@media (max-width: 935px){
	// width:100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: rgba(243, 245, 249, 0.34);
}

`;

export const LoaderDiv = styled.div`
display: flex;
flex-direction:column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
img{margin-top: 5px;};
position:absolute;
top:0;
background: #ffffff8f;

`;

export const StripProductionWrap= styled.div<props>`
  display: flex;
  // position: ${(props) => (props.fullScreenEnabled ? 'fixed' : 'absolute')};
  position: absolute;
  align-items: end;
  justify-content: center;
  pointer-events: none;
  bottom: 1.10rem;
  left: 0;
  right:0;
  margin: 0 auto;
  max-width: 240px;
    width: 100%;
@media (max-width: 769px){
 position: absolute;
}

    @media (max-width: 514px){
      position: absolute;
      left: 13%;
      margin: 0;
    }
`;
export const MoveZoomWrapper= styled.div<props>`
// position: ${(props) => (props.fullScreenEnabled ? 'fixed' : 'absolute')};
  .zoombutton-wrap{
      &:before{
        height: 1px;
        width: 20px;
        left: 9px;
        top: 38px;
      }
    }

    &.show-irradiance{
      right: 10px;
      display: flex;
      align-items: flex-start;
      bottom: 2px;
      flex-direction:row-reverse;
      gap: .35rem;
      .zoombutton-wrap{
        display:flex;
        gap: .15rem;

        &:before{
          top: 9px;
          height: 18px;
          width: 1px;
          left:50%;
        }
      }
    }
`;

export const DivWrap = styled.div`
 width: 100%;
 position: relative;
`;

export const BarLoader = styled.div`
	position: absolute;
	top:0;
	left: 0;
	right: 0;
  width: 100%;
  padding-bottom: 8px;
  background: linear-gradient(var(--primary) 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 3s linear infinite;

	@keyframes l2 {to{background-size: 100% 3px}}
`;
