import { AxiosError } from 'axios';
import { createNewFacets } from 'components/tool/Editor/ButtonDiv/api';
import { transformNewlyAddedFacets } from 'components/tool/Editor/util';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { errorResponseHandler } from 'store/api/AxiosError';
import { useMutation } from 'react-query';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

export default function useCreateDeleteFacet() {

	const { newlyCreatedFacets, deletedRoofIndexes, groundMountEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { uuid, panel, mode } = useSelector((state: RootState) => state.roofData.data);
	const scale = useKonvaImageScale();

	const createNewFacetsMutation = useMutation(
		async () => {
			const newPanelFacets = transformNewlyAddedFacets({ mode, newlyCreatedFacets, scale, groundMountEnabled });
			if (!newPanelFacets.length && !deletedRoofIndexes.length) throw new Error('E_NO_FACET_TO_ADD_OR_DELETE');
			await createNewFacets({ newPanelFacets, deletedRoofSegs: deletedRoofIndexes, },
				panel.key,
				uuid
			);
		},
		{
			onError(error: AxiosError) {
				if (error.message === 'E_NO_FACET_TO_ADD_OR_DELETE') return;
				const message = errorResponseHandler(error).message;
				toast.error(message);
			},
			mutationKey: 'create-new-facets',
		});

	return createNewFacetsMutation;
}