import { Transformer } from 'react-konva';
import { AZIMUTH_CONSTRAINT } from '../../../../constants';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import Konva from 'konva';
import { normalizeAngle } from '../../utils';

interface Props extends Konva.NodeConfig {
	selectedRoofSegment: RasterRoofSegment | undefined;
	onTransformEnd: () => void;
}

const normalizeAngleTo180 = (angle: number) => (((angle + 180) % 360) - 180);

const TransformerComponent = forwardRef<Konva.Transformer | null, Props>((props, ref) => {
	const innerTrRef = useRef<Konva.Transformer | null>(null);
	const initialRotation = props.selectedRoofSegment?.azimuthDegrees || 0;
	
	const transformTimerRef = useRef<{
		timeElapsed: number,
		timeOut?: NodeJS.Timeout
	}>({
		timeElapsed: 0
	});

	const [minRotation, maxRotation] = useMemo(() => {

		const minRotation = normalizeAngleTo180(initialRotation - AZIMUTH_CONSTRAINT);//anticlock
		const maxRotation = normalizeAngleTo180(initialRotation + AZIMUTH_CONSTRAINT);//clock

		return [minRotation, maxRotation];
	}, [initialRotation]);

	const constraintHandleRotation = useCallback((() => {
		let previousAngle = innerTrRef.current?.getAbsoluteRotation();
		let groupId = innerTrRef.current?.getNode()?.attrs?.id;
		return function () {
			const currentGroupId = innerTrRef.current?.getNode()?.attrs?.id;

			if (!previousAngle || currentGroupId !== groupId) {
				previousAngle = innerTrRef.current?.getAbsoluteRotation();
				groupId = currentGroupId;
				return;
			}

			const newAngle = innerTrRef.current?.getAbsoluteRotation();
			if (!newAngle) return;

			const normalizedInitialAngle = normalizeAngleTo180(initialRotation);
			const isInNegativeQuadrant = Math.abs(normalizedInitialAngle) > 90;
	
			if (isInNegativeQuadrant) {
				const normalizedNewAngle = normalizeAngle(newAngle);
				const normalizedPreviousAngle = normalizeAngle(previousAngle);
				const normalizedMaxRotaionAngle = normalizeAngle(maxRotation);
				const normalizedMinRotaionAngle = normalizeAngle(minRotation);

				const userIsRotatingFromLeftToRight = normalizedNewAngle > normalizedPreviousAngle && normalizedNewAngle < normalizedMaxRotaionAngle;
				const userIsRotatingFromRightToLeft = normalizedNewAngle < normalizedPreviousAngle && normalizedNewAngle > normalizedMinRotaionAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (normalizedNewAngle > normalizedMaxRotaionAngle || normalizedNewAngle < normalizedMinRotaionAngle) {
					innerTrRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			} else {
				const userIsRotatingFromLeftToRight = newAngle > previousAngle && newAngle < maxRotation;
				const userIsRotatingFromRightToLeft = newAngle > minRotation && newAngle < previousAngle;

				if (userIsRotatingFromLeftToRight || userIsRotatingFromRightToLeft) {
					previousAngle = newAngle;
					return;
				}

				if (newAngle > maxRotation || newAngle < minRotation) {
					innerTrRef.current?.stopTransform();
					previousAngle = newAngle;
					return;
				}
			}
		};
	})(), [maxRotation, minRotation]);

	return (
		<Transformer
			ref={(elm) => {
				innerTrRef.current = elm;
				if (typeof ref === 'function') ref(elm);
				else if (ref) ref.current = elm;
			}}
			visible={props.visible}
			anchorSize={20}
			resizeEnabled={false}
			borderEnabled={true}
			rotateAnchorOffset={25}
			borderStroke={'rgba(255, 147, 43, 0.80)'}
			anchorStroke={'rgba(255, 147, 43, 0.80)'}
			anchorFill={'rgba(255, 147, 43, 0.80)'}
			borderStrokeWidth={2}
			onTranformStart={() => {
				transformTimerRef.current.timeElapsed = performance.now();
				clearTimeout(transformTimerRef.current.timeOut);
			}}
			onTransform={constraintHandleRotation}
			onTransformEnd={() => {
				props.onTransformEnd();
				transformTimerRef.current.timeElapsed = performance.now() - transformTimerRef.current.timeElapsed;
				queueMicrotask(() => {
					transformTimerRef.current.timeOut = setTimeout(() => {
						transformTimerRef.current.timeElapsed = 0;
					}, 200);
				});
			}}
		/>
	);
});

TransformerComponent.displayName = 'TransformerComponent';

export default TransformerComponent;